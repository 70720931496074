// Here you can add other styles
@font-face {
    font-family: 'heroregular';
    src: url('../fonts/hero-regular-webfont.eot');
    src: url('../fonts//hero-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/hero-regular-webfont.woff2') format('woff2'),
         url('../fonts/hero-regular-webfont.woff') format('woff'),
         url('../fonts/hero-regular-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}

.form-login{
    margin-top: "100px";
}